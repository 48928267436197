import './styles/main.scss';
import {useEffect, useState} from "react";

function App() {
    const [state, setState] = useState({
        username: "",
        password: ""
    })
    const [showAlert, setShowAlert] = useState(false);
    const setUsername = (username) => {
        setState({...state, username})
    }
    const setPassword = (password) => {
        setState({...state, password})
    }
    console.log(state.username.password > 0)

    const onClick = () => {
        if (state.username.length > 0 && state.username.length > 0) {
            setShowAlert(true)
            setTimeout(() => setTimeout(false), 3000)
        }
    }
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])
    return (<>
        <main className="main-container">
            <div className="main-logo">
                <div className="dib ">
                    <span className="logo-main-content">
                        <span className="logo-brand">
                            ESYS
                            <span className="logo-spacing">&nbsp;</span>
                            <span className="logo-slash">/</span>
                        </span>
                        <span className="logo-client" id="accountName">
                            CONNECT / INNOVATE
                        </span>
                    </span>
                    <span className="logo-subtitle">Digital Platform</span>
                </div>
            </div>
            <div className="card">
                <h1 className={"card-heading"}>Welcome to EPAM SYSTEM</h1>

                <div id="usernameLabel" className="uk-width-1-1@s uk-first-column input-slide-up">
                    <label htmlFor="username" className="uk-form-label active">Corporate email </label>
                    <div className="uk-form-controls">
                        <div>
                            <input tabIndex="1"
                                   id="username"
                                   className="uk-input uk-input-success"
                                   name="username"
                                   onChange={e => setUsername(e.target.value)}
                                   type="text" autoFocus=""
                                   autoComplete="off"
                            />
                        </div>
                    </div>
                </div>

                <div id="passwordLabel" className="uk-width-1-1@s uk-first-column input-slide-up">
                    <label htmlFor="password" className="uk-form-label active">Password</label>
                    <div className="uk-form-controls">
                        <div>
                            <input tabIndex="1"
                                   id="password"
                                   className="uk-input uk-input-success"
                                   name="password"
                                   onChange={e => setPassword(e.target.value)}
                                   type="password" autoFocus=""
                                   autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                <span className={"text-warning"} data-show-element={showAlert}>
                    Network Alert!!! <br/>
                Secure VPN connection not found
                </span>
                <button
                    onClick={onClick}
                    tabIndex="4"
                    className="uk-button uk-align-right"
                    id="kc-login-next"
                    type="submit">
                    Continue
                </button>
            </div>
        </main>
        <footer className={"footer"} >
            <p>
                EPAM SYSTEM INC operates as a distinguished and autonomous provider of IT and technology services,
                entirely separate from any associated entities. Despite any legal distinctions between EPAM
                and ESYS, our joint dedication to excellence might inadvertently blur the lines. For additional details
                about EPAM, feel free to {" "}
                <span>
                <a href="https://www.epam.com">
               <b>Click here</b>
            </a>
            </span>
            </p>
        </footer>

    </>)
}

export default App;


